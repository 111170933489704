<template>
  <div v-if="!hasPermission('List Place Insurance')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
          <div class="card">
            <div class="card-header">
              <div class="card-title">
                <div class="d-flex align-items-center position-relative my-1">
                  <span class="svg-icon svg-icon-1 position-absolute ms-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                      <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                    </svg>
                  </span>
                  <input type="text" data-kt-user-table-filter="search" v-model="search"
                         class="form-control form-control-solid w-250px ps-14"
                         placeholder="Pesquisar ...">
                </div>
              </div>
              <div class="card-toolbar">
                <div class="col-md-6">
                  <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                          v-if="hasPermission('Store Place Insurance')"
                          title="Novo convênio" data-bs-target="#modal_store_insurance" @click="searchInsurance='';formStore.id_insurance='';formStoreErrors=[];">
                    <i class="bi bi-plus-lg"></i> Adicionar
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body py-4">
              <table class="table table-row-bordered gy-5">
                <thead>
                  <tr class="fw-bold fs-6 text-muted">
                    <th>Convênio</th>
                    <th>Apelido</th>
                    <th>Valor</th>
                    <th>Disp. Paciente?</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="insurance in insurancesFiltered">
                    <td>{{ insurance.code_ans }} - {{insurance.insurance_name}}</td>
                    <td>{{insurance.place_insurance.nick_name}}</td>
                    <td>R$ {{Number(insurance.place_insurance.value).toFixed(2).toString().replace(".", ",")??'0,00'}}</td>
                    <td>{{insurance.place_insurance.available_to_patient ? 'Sim' : 'Não'}}</td>
                    <td class="text-center" style="min-width: 125px;">
                      <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                              v-if="hasPermission('Update Place Insurance')"
                              data-bs-toggle="modal"
                              data-bs-target="#modal_edit_insurance"
                              @click="formEditErrors=[];onClickBtnEdit(insurance)"
                              title="Editar Convênio">
                        <i class="bi bi-pencil-square"></i>
                      </button>
                      <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1" @click="deleteInsurance(insurance.id_insurance)"
                              v-if="hasPermission('Delete Place Insurance')"
                              data-bs-toggle="tooltip" title="Excluir Convênio">
                        <i class="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal fade" tabindex="-1" id="modal_store_insurance">
            <div class="modal-dialog modal-lg">
              <form method="post" @submit.prevent="submitFormStore">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Adicionar Convênio</h5>
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
                      <i class="bi bi-x-lg"></i>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="col-md-12">
                      <div class="mb-6">
                        <div class="d-flex align-items-center position-relative my-1">
                          <span class="svg-icon svg-icon-1 position-absolute ms-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                              <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                            </svg>
                          </span>
                          <input type="text" data-kt-user-table-filter="search" v-model="searchInsurance"
                                 class="form-control form-control-solid ps-14"
                                 placeholder="Pesquisar o convênio...">
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-6">
                        <div class="mt-4">
                          <label for="id_insurance_store" class="required form-label">Selecione o Convênio</label>
                          <select class="form-control form-control-solid" id="id_insurance_store"
                                  size="5"  aria-label="Selecione o convênio" v-model="formStore.id_insurance" required>
                            <option :value="insurance.id_insurance" v-for="insurance in allInsurancesFiltered">
                              {{ insurance.code_ans }} - {{ insurance.insurance_name }}
                            </option>
                          </select>
                        </div>
                        <small class="text-danger" v-if="formStoreErrors.id_insurance">{{ formStoreErrors.id_insurance[0] }}</small>
                      </div>
                    </div>
                    <div class="row mt-4 mb-6">
                      <div class="col-md-8">
                        <label for="nick_name_block_store" class="form-label required">Apelido</label>
                        <input type="text" class="form-control form-control-solid"
                               id="nick_name_block_store" maxlength="30" v-model="formStore.nick_name" required>
                        <small class="text-danger" v-if="formStoreErrors.nick_name">{{ formStoreErrors.nick_name[0] }}</small>
                      </div>
                      <div class="col-md-4">
                        <label for="value_lock_store" class="form-label required">Valor da Consulta</label>
                        <input type="number" class="form-control form-control-solid"
                               min="0.00" step="0.01"
                               id="value_lock_store" v-model="formStore.value" required>
                        <small class="text-danger" v-if="formStoreErrors.value">{{ formStoreErrors.value[0] }}</small>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label for="observation_block_store" class="form-label">Observação</label>
                        <textarea  type="text" class="form-control form-control-solid"
                                   id="observation_block_store" maxlength="255" rows="3" v-model="formStore.observation" >
                              </textarea>
                        <small class="text-danger" v-if="formStoreErrors.observation">{{ formStoreErrors.observation[0] }}</small>
                      </div>
                      <div class="col-md-12">
                        <div class="mb-6">
                          <div class="row form-check form-switch">
                            <label class="form-check-label" for="sto_available_to_patient">
                              Disponível para agendamento pelo paciente?</label>
                            <input class="form-check-input" type="checkbox" role="switch" id="sto_available_to_patient"
                                   v-model="formStore.available_to_patient">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                    <button type="submit" class="btn btn-primary">Cadastrar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="modal fade" tabindex="-1" id="modal_edit_insurance">
            <div class="modal-dialog modal-lg">
              <form method="post" @submit.prevent="submitFormEdit">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Editar Convênio</h5>
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
                      <i class="bi bi-x-lg"></i>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div class="col-md-12">
                      <div class="mb-6">
                        <label for="insurance_name_block_edit" class="form-label required">Convênio</label>
                        <input type="text" class="form-control form-control-solid"
                               disabled="true"
                               id="insurance_name_block_edit" v-model="formEdit.insurance_name">
                      </div>
                    </div>
                    <div class="row mb-6">
                      <div class="col-md-8">
                        <label for="nick_name_block_edit" class="form-label required">Apelido</label>
                        <input type="text" class="form-control form-control-solid"
                               id="nick_name_block_edit" maxlength="30" v-model="formEdit.nick_name" required>
                        <small class="text-danger" v-if="formEditErrors.nick_name">{{ formEditErrors.nick_name[0] }}</small>
                      </div>
                      <div class="col-md-4">
                        <label for="value_lock_edit" class="form-label required">Valor da Consulta</label>
                        <input type="number" class="form-control form-control-solid"
                               min="0.00" step="0.01"
                               id="value_lock_edit" v-model="formEdit.value" required>
                        <small class="text-danger" v-if="formEditErrors.value">{{ formEditErrors.value[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-6">
                        <label for="observation_block_edit" class="form-label">Observação</label>
                        <textarea  type="text" class="form-control form-control-solid"
                                   id="observation_block_edit" maxlength="255" rows="3" v-model="formEdit.observation">
                              </textarea>
                      </div>

                      <div class="col-md-12">
                        <div class="mb-6">
                          <div class="row form-check form-switch">
                            <label class="form-check-label" for="available_to_patient">
                              Disponível para agendamento pelo paciente?</label>
                            <input class="form-check-input" type="checkbox" role="switch" id="available_to_patient"
                                   v-model="formEdit.available_to_patient">
                          </div>
                        </div>
                        </div>
                      </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                    <button type="submit" class="btn btn-primary">Salvar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

      </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Permission from "../../helpers/permission";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Insurance",
    components: {
        Toolbar,
    },
  data() {
    return {
      search: '',
      insurances: [],
      allInsurances: [],
      searchInsurance: '',
      formStore: {
        id_insurance: '',
        observation: '',
        available_to_patient: '',
        nick_name: '',
        value: '',
      },
      formStoreErrors: [],
      formEdit: {
        id_insurance: '',
        insurance_name: '',
        observation: '',
        available_to_patient: '',
        nick_name: '',
        value: '',
      },
      formEditErrors: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    insurancesFiltered() {
      return this.insurances.filter(insurance => {
        return insurance.insurance_name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    allInsurancesFiltered() {
      return this.allInsurances.filter(insurance => {
        return insurance.insurance_name.toLowerCase().includes(this.searchInsurance.toLowerCase());
      });
    },

  },
  mounted() {
      this.loadAllInsurances();
      this.loadInsurances();
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadAllInsurances() {
      http.get(`/insurances`).then((response) => {
        this.allInsurances = response.data;
      });
    },
    loadInsurances() {
      http.get(`/places/${this.place.id_place}/insurances`).then((response) => {
        this.insurances = response.data;
      });
    },
    submitFormStore() {
      this.formStoreErrors = [];
      http.post(`/places/${this.place.id_place}/insurances`, this.formStore).then(_ => {
        this.loadInsurances();
        Alert.toastSuccess("Convênio cadastrado com sucesso.");
        this.formStore = {
          id_insurance: '',
          observation: '',
          available_to_patient: '',
          nick_name: '',
          value: '',
        };
        bootstrap.Modal.getInstance(document.getElementById('modal_store_insurance')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
        if (e.response.data.errors) {
          this.formStoreErrors = e.response.data.errors;
        }
      });
    },
    submitFormEdit() {
      const place_insurance = {
        "id_insurance": this.formEdit.id_insurance,
        "observation": this.formEdit.observation,
        "available_to_patient": this.formEdit.available_to_patient,
        "nick_name": this.formEdit.nick_name,
        "value": this.formEdit.value
      };
      http.put(`/places/${this.place.id_place}/insurances/${this.formEdit.id_insurance}`, place_insurance).then(() => {
        this.loadInsurances();
        Alert.toastSuccess("Convênio atualizado com sucesso.");

        bootstrap.Modal.getInstance(document.getElementById('modal_edit_insurance')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data.message??e.response.data);
        if (e.response.data.errors) {
          this.formEditErrors = e.response.data.errors;
        }
      });
    },
    onClickBtnEdit(insurance) {
      this.formEdit.id_insurance = insurance.id_insurance;
      this.formEdit.insurance_name = insurance.insurance_name;
      this.formEdit.observation = insurance.place_insurance.observation;
      this.formEdit.available_to_patient = insurance.place_insurance.available_to_patient;
      this.formEdit.nick_name = insurance.place_insurance.nick_name;
      this.formEdit.value = insurance.place_insurance.value;
    },
    deleteInsurance(insuranceId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover o convênio?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/places/${this.place.id_place}/insurances/${insuranceId}`).then(_ => {
            this.loadInsurances();
            Alert.toastSuccess("Convênio excluÍdo com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data);
          })
        }
      });
    },

  }
}
</script>

<style scoped>

</style>